import fields from './models/agent-inbox-table-fields'
import agentInboxHtml from './agent-inbox.html'
import rowsPerPageOptions from './models/rows-per-page-options'
import Api from './api.js'
import sorting from "./models/sorting";
import bus from "./bus"
import {siteConfig, terminology} from "./app";
import newEnrollmentModal from './new-enrollment-modal'
import formats from "./models/formats";

const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;

export default {
  template: agentInboxHtml,
  components: {
    sorting,
    newEnrollmentModal
  },
  props: {
    tabName: {
      type: String,
      default: 'Inbox',
    },
    displayName: {
      type: String,
      default: 'Inbox',
    },
  },
  data: function() {
    return {
      fields: fields,
      tableIsLoading: false,
      isLoading: false, // this should only be set by calling showLoading()
      enrollmentSessions: [],
      rowsPerPageOptions: rowsPerPageOptions,
      currentPage: 1,
      rowsPerPage: 25,
      totalCount: 0,
      defaultSortColumn: 'created_at',
      sortBy: this.defaultSortColumn,
      sortDesc: true,
      errors: [],
      lastClickedSessionId: null,
      lastClickedSessionDetails: {},
      showSignEnrollmentsModal: false,
      fbdLabel: "Desired First Bill Date (Effective Policy Date)",
      fbdDate: null,
      cases: [],
      casesFilterOptions: [],
      newEnrollment: {},
      showEnrollModal: false,
    }
  },
  methods: {
    getInboxSessions() {
      let params = {
        rows_per_page: this.rowsPerPage,
        page: this.currentPage,
        sort_column: this.sortBy ? this.sortBy : this.defaultSortColumn,
        sort_direction: this.sortDesc ? 'DESC' : 'ASC'
      };

      this.tableIsLoading = true;
      Api.getAgentUnsignedSessions(params)
        .then(response => {
          this.tableIsLoading = false;
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            response.rows = response.rows.map((es) => {
              es.agent_name = es.enrollments[0].agent_name;
              es.case_name = es.enrollments[0].case_name;
              es.employee_name = es.enrollments[0].employee_name;
              es.time = es.enrollments[0].time;
              es.status = es.enrollments[0].status;
              return es;
            });
            this.enrollmentSessions = response.rows;
            this.currentPage = response.page;
            this.rowsPerPage = response.rows_per_page;
            this.totalCount = response.total_count;
          }
        })
    },
    sortChanged(header) {
      if(header.sortBy) {
        this.sortBy = header.sortBy;
        this.sortDesc = header.sortDesc;
        this.getInboxSessions();
      }
    },
    openSignModal(session_id) {
      this.lastClickedSessionId = session_id;
      this.showLoading(true);
      Api.getEnrollmentSessionDetails(this.lastClickedSessionId)
        .then(response => {
          this.showLoading(false);
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.lastClickedSessionDetails = response;
            if (this.lastClickedSessionDetails.employee_first_bill_date) {
              this.fbdDate = this.lastClickedSessionDetails.employee_first_bill_date;
            }
            this.showSignEnrollmentsModal = true;
          }
        })
    },
    showSessionPDF(session_id) {
      window.open(`${API_BASE_PATH}/api/enrollmentsessions/${session_id}/pdf`);
    },
    closeAndSignEnrollmentSession() {
      this.showSignEnrollmentsModal = false;
      this.signEnrollmentSession(this.lastClickedSessionId, this.fbdDate);
    },
    signEnrollmentSession(session_id, firstBillDate) {
      if (this.fbdValidationState !== true) {
        return;
      }
      this.showLoading(true);

      let formData = null;
      if (firstBillDate) {
        formData = { desired_first_bill_date: firstBillDate };
      }

      Api.agentSignSessionEnrollments(session_id, formData)
        .then(response => {
          this.showLoading(false);
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.getInboxSessions();
          }
        })
    },
    getTomorrowDateString() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0);
      return tomorrow.toISOString().split('T')[0];
    },
    searchCases() {
      return Api.searchCases()
        .then(response => {
          this.cases = response;
          this.casesFilterOptions = this.mapCases();
        });
    },
    mapCases() {
      let options = this.cases.map(c => {
        return {text: c.group_name, value: c.id};
      });
      options = formats.alphebetize(options, 'text');
      options.unshift({text: 'Select Case', value: 0});
      return options;
    },
    resumeEnrollment(data, startAudioConference = false) {
      const enrollmentDetail = data.enrollments.find((enrollment)=> enrollment.employee_name === data.employee_name);
      let selectedCase = this.cases.filter(c => c.id === data.case_id)[0];
      let enrollmentId = enrollmentDetail ? enrollmentDetail.id : null;
      let applicantName = enrollmentDetail ? enrollmentDetail.employee_name: '';

      // Get defaults for city and state. Prioritize local settings over case settings.
      let enrollmentCityDefault = selectedCase.situs_city;
      let enrollmentStateDefault = selectedCase.situs_state;
      if (localStorage.enrollmentCity && localStorage.enrollmentState) {
        enrollmentCityDefault = localStorage.enrollmentCity;
        enrollmentStateDefault = localStorage.enrollmentState;
      }

      let isAudioConferenceEnrollment = startAudioConference;
      let primaryApplicantNumber = null;
      let agentNumber = null;

      // If we have done previous conferences, assume we will do one again.
      // @TODO test this! -Ed F 2021-11-12
      // if (this.enrollmentDetails.enrollment_session_conferences) {
      //   isAudioConferenceEnrollment = true;
      //   const lastConference = this.enrollmentDetails.enrollment_session_conferences[this.enrollmentDetails.enrollment_session_conferences.length - 1];
      //   if (lastConference) {
      //     agentNumber = lastConference.agent_number;
      //     primaryApplicantNumber = lastConference.primary_applicant_number;
      //   }
      // }
      this.newEnrollment = {
        caseId: selectedCase.id,
        enrollmentCity: enrollmentCityDefault,
        enrollmentState: enrollmentStateDefault,
        enrollmentId: enrollmentId,
        name: applicantName,
        isPaused: false,
        isPendingAgentCompletion: true,
        primaryApplicantNumber: primaryApplicantNumber,
        agentNumber: agentNumber,
        isAudioConferenceEnrollment: isAudioConferenceEnrollment,
      }

      this.showEnrollModal = true;
    },
    showLoading: function(state) {
      this.$emit('update:showLoading', state);
      this.isLoading = state;
    }
  },
  computed: {
    showResume() {
      if (siteConfig) {
        return siteConfig.tenantDisplayNameShort === 'CELIC'
      }
      return false
    },
    isReplacingText() {
      if (this.lastClickedSessionDetails && this.lastClickedSessionDetails.is_replacing) {
        return 'will'
      }
      return 'will not'
    },
    shouldShowFirstBillDate() {
      return !!(this.lastClickedSessionDetails && this.lastClickedSessionDetails.should_show_first_bill_date);
    },
    fbdValidationState() {
      if (!this.shouldShowFirstBillDate) {
        return true;
      }
      return new Date(this.fbdDate) >= new Date(this.fbdMinValue);
    },
    fbdInvalidFeedback() {
      return "First Desired Bill Date must be in future.";
    },
    fbdMinValue() {
      return this.getTomorrowDateString();
    }
  },

  watch: {
    rowsPerPage: function() {
      if (!this.isLoading) {
        localStorage.rowsPerPage = this.rowsPerPage;
        this.getInboxSessions();
      }
    },
    currentPage: function() {
      if (!this.isLoading) {
        this.getInboxSessions();
      }
    },
    totalCount: function() {
      if (this.totalCount >= 0) {
        this.$emit('update:totalCount', this.totalCount);
      }
    }
  },
  created() {
    let rowsPerPage = parseInt(localStorage.rowsPerPage);
    this.rowsPerPage = (rowsPerPage && this.rowsPerPageOptions.includes(rowsPerPage)) ? rowsPerPage : this.rowsPerPage;
    this.getInboxSessions();

    // setup event bus handlers
    bus.$on('refreshAgentInbox', () => {
      this.getInboxSessions();
    });
  },
  mounted () {
    this.searchCases();
  }
}
