const enrollmentStatuses = {
  STATUS_PAUSED: 'paused',
  STATUS_WAIVED : 'waived',
  STATUS_DECLINED : 'declined',
  STATUS_ENROLLED : 'enrolled',
  STATUS_EXPIRED : 'expired',
  STATUS_PENDING_APPLICANT_SIGNATURE : 'pending_applicant_signature',
  STATUS_PENDING_AGENT_SIGNATURE : 'pending_agent_signature',
  STATUS_PENDING_AGENT_COMPLETION : 'pending_agent_completion' // for email only application mode
};
export default enrollmentStatuses;
