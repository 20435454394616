<template>
  <div>
    <div class="hide-selection form-group">
      <b-card-title>{{ 'enrollments' | terminology({isTitle: true, isPlural: true}) }} Modes</b-card-title>
      <div v-if="allowAdvanceEnrollmentOption">
        <div @click="toggleSelfEnrollActive"
           class="row pointer mt-3">
        <div class="col-0 pl-3">
          <b-form-checkbox
            id="checkbox-is-self-enroll-active"
            v-model="caseObject.is_self_enroll_active"
            name="checkbox-is-self-enroll-active"
          >
            Allow via Self-Enrollment
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="caseObject.is_self_enroll_active" class="mt-3">
        <div class="form-group">
          <label>Self-enrollment URL:</label>
          <b-input-group>
            <b-form-input
              @click="handleCopyURLClick('selfEnrollUrlElement')"
              id="self-enroll-url"
              ref="selfEnrollUrlElement"
              class="col"
              v-model="selfEnrollUrl"
              type="text"
              :readonly="true">
            </b-form-input>
            <b-input-group-append>
              <b-btn variant="primary" @click="handleCopyURLClick('selfEnrollUrlElement')">Copy</b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-tooltip triggers="" :show.sync="showCopyUrlTooltip" target="self-enroll-url" placement="bottom">
            URL copied!
          </b-tooltip>
        </div>
        <div class="hide-selection form-group mt-3">
          <label class="mb-0">
            <span>Self-enroll agent:</span>
          </label>
          <multiselect
            v-if="userCanAssignUserToCase"
            v-model="selectedSelfEnrollAgent"
            :options="users"
            label="text"
            track-by="value"
            :hide-selected="false"
            :close-on-select="true"
            :show-labels="false"
            :allow-empty="false"
            placeholder="Search and select a user"
            open-direction="bottom">
          </multiselect>
        </div>

      </div>

      <div @click="toggleAgentEnrollRemoteSigActive"
           class="row pointer mt-3">
        <div class="col-0 pl-3">
          <b-form-checkbox
            id="checkbox-is-agent-enroll-remote-sig-active"
            v-model="caseObject.is_agent_enroll_remote_sig_active"
            name="checkbox-is-agent-enroll-remote-sig-active"
          >
            Allow agent direct-start link (with applicant signature via email)
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="caseObject.is_agent_enroll_remote_sig_active" class="mt-3">
        <div class="form-group">
          <label>Agent enrollment URL:</label>
          <b-input-group>
            <b-form-input
              @click="handleCopyURLClick('agentEnrollUrlElement')"
              id="agent-enroll-url"
              ref="agentEnrollUrlElement"
              class="col"
              v-model="agentEnrollUrl"
              type="text"
              :readonly="true">
            </b-form-input>
            <b-input-group-append>
              <b-btn variant="primary" @click="handleCopyURLClick('agentEnrollUrlElement')">Copy</b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-tooltip triggers="" :show.sync="showCopyUrlTooltipAgentEnroll" target="agent-enroll-url"
                     placement="bottom">
            URL copied!
          </b-tooltip>
        </div>
      </div>

      <div v-if="siteConfig.ffEnableEnrollmentSessionConferences">
        <div class="row mt-3">
          <div class="col-0 pl-3">
            <b-form-checkbox
              id="checkbox-is-audio-conference-enroll-active"
              v-model="caseObject.is_audio_conference_enroll_active"
              @input="emitAudioConferenceEnrollActiveChanged"
              name="checkbox-is-audio-conference-enroll-active"
            >Allow Voice Enrollment for Agents
            </b-form-checkbox>
          </div>
        </div>
        <div v-if="caseObject.is_audio_conference_enroll_active" class="">
          <b-form-group class="mt-2">
            <b-form-radio class="ml-4" @click="setForceAudioConferenceEnroll(true)"
                          v-model="caseObject.force_audio_conference_enroll"
                          name="force_audio_conference_enroll--radios" :value="true">…instead of in-person
              agent-assisted
              enrollments
            </b-form-radio>
            <b-form-radio class="ml-4" @click="setForceAudioConferenceEnroll(false)"
                          v-model="caseObject.force_audio_conference_enroll"
                          name="force_audio_conference_enroll--radios" :value="false">…in addition to in-person
              agent-assisted
              enrollments
            </b-form-radio>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-0 pl-3">
          <b-form-checkbox
            id="checkbox-is-call-center-mode-active"
            v-model="caseObject.is_call_center_mode_active"
            @input="emitCallCenterModeChanged"
            name="checkbox-is-call-center-mode-active"
          >Allow 3rd-party call center enrollments
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="caseObject.is_call_center_mode_active" class="">
        <b-form-group class="mt-2">
          <b-form-radio class="ml-4" @click="setForceCallCenterMode(true)" v-model="caseObject.force_call_center_mode"
                        name="force_call_center_mode-radios" :value="true">…instead of in-person agent-assisted
            enrollments
          </b-form-radio>
          <b-form-radio class="ml-4" @click="setForceCallCenterMode(false)" v-model="caseObject.force_call_center_mode"
                        name="force_call_center_mode-radios" :value="false">…in addition to in-person agent-assisted
            enrollments
          </b-form-radio>
        </b-form-group>
      </div> 
      </div>

      <b-form-group class="mt-4" :label="signatureLabel">
        <b-form-radio class="ml-4" @click="setForceAgentEnrollRemoteSig(true)" v-model="caseObject.force_agent_enroll_remote_sig" name="some-radios" :value="true">…via email only
        </b-form-radio>
        <b-form-radio class="ml-4" @click="setForceAgentEnrollRemoteSig(false)" v-model="caseObject.force_agent_enroll_remote_sig" name="some-radios" :value="false">…via email or in-person
        </b-form-radio>
      </b-form-group>
    </div>


  </div>

</template>
<script>
import bus from "../bus";
import Api from "../api";
import {siteConfig, terminology} from "../app";

let SelfEnrollmentCaseSettings = {
  name: "SelfEnrollCaseSettings",
  computed: {
    siteConfig() {
      return siteConfig
    },
    allowAdvanceEnrollmentOption() {
      if (siteConfig) {
        return siteConfig.tenantDisplayNameShort !== 'CELIC'
      }
      return true
    },
    signatureLabel() {
      return `For agent-assisted ${terminology.formatEnrollment({ isPlural: true })}, applicant signatures will be…`;
    },
  },
  props: [
    "caseObject",
    "users",
    "userPermissions",
  ],
  data: function () {
    return {
      loading: false,
      selfEnrollUrl: '',
      agentEnrollUrl: '',
      selectedSelfEnrollAgent: null,
      // selfEnrollUrlElementId: "self-enroll-url",
      // agentEnrollUrlElementId: "agent-enroll-url",
      showCopyUrlTooltip: false,
      showCopyUrlTooltipAgentEnroll: false,
    }
  },
  created() {
    this.loading = true;
    Api.getSelfEnrollUrlForCase(this.caseObject.id).then(response => {
      this.selfEnrollUrl = response.url;
      this.loading = false;
    });
    Api.getAgentEnrollUrlForCase(this.caseObject.id).then(response => {
      this.agentEnrollUrl = response.url;
      this.loading = false;
    });

    // convert user object of self_enroll_agent multiselect-compat value
    if (this.caseObject.self_enroll_agent) {
      this.selectedSelfEnrollAgent = {
        value: this.caseObject.self_enroll_agent.id,
        text: (this.caseObject.self_enroll_agent.first_name + ' ' + this.caseObject.self_enroll_agent.last_name)
      }
    }
  },
  methods: {
    userCanAssignUserToCase() {
      return this.userPermissions.canManageCaseUsers();
    },
    toggleSelfEnrollActive() {
      this.caseObject.is_self_enroll_active = !this.caseObject.is_self_enroll_active;
      bus.$emit('case-data-changed', {is_self_enroll_active: this.caseObject.is_self_enroll_active});
    },
    toggleAgentEnrollRemoteSigActive() {
      this.caseObject.is_agent_enroll_remote_sig_active = !this.caseObject.is_agent_enroll_remote_sig_active;
      bus.$emit('case-data-changed', {is_agent_enroll_remote_sig_active: this.caseObject.is_agent_enroll_remote_sig_active});
    },
    emitCallCenterModeChanged() {
      //this.caseObject.is_call_center_mode_active = !this.caseObject.is_call_center_mode_active;
      bus.$emit('case-data-changed', {is_call_center_mode_active: this.caseObject.is_call_center_mode_active});
    },
    emitAudioConferenceEnrollActiveChanged() {
      //this.caseObject.is_audio_conference_enroll_active = !this.caseObject.is_audio_conference_enroll_active;
      bus.$emit('case-data-changed', {is_audio_conference_enroll_active: this.caseObject.is_audio_conference_enroll_active});
    },
    setForceAgentEnrollRemoteSig(value) {
      bus.$emit('case-data-changed', {force_agent_enroll_remote_sig: this.caseObject.force_agent_enroll_remote_sig});
    },
    setForceCallCenterMode(value) {
      bus.$emit('case-data-changed', {force_call_center_mode: this.caseObject.force_call_center_mode});
    },
    setForceAudioConferenceEnroll(value) {
      bus.$emit('case-data-changed', {force_audio_conference_enroll: this.caseObject.force_audio_conference_enroll});
    },
    handleCopyURLClick(ref) {
      let el = this.$refs[ref];//.$el;
      el.select(); // select the text in the element

      try {
        if (document.execCommand('copy')) {
          if (ref === 'selfEnrollUrlElement') {
            this.showCopyUrlTooltip = true
          } else if (ref === 'agentEnrollUrlElement') {
            this.showCopyUrlTooltipAgentEnroll = true
          }
        }
      } catch (err) {
        alert('Problem copying to clipboard. Try copying manually.');
      }

      // close tooltip after 2 seconds
      setTimeout(() => {
        this.showCopyUrlTooltip = false;
        this.showCopyUrlTooltipAgentEnroll = false;
      }, 2000);

      // unselect the range
      window.getSelection().removeAllRanges()
    },
  },
  watch: {
    selectedSelfEnrollAgent() {
      bus.$emit('case-data-changed', {self_enroll_agent_id: this.selectedSelfEnrollAgent.value || null});
    }
  },
};

export default SelfEnrollmentCaseSettings;
</script>
